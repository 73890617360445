import { InstantSearch } from "react-instantsearch-dom";
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";
import HeaderSearchAutocomplete from "components/header/search-autocomplete";
import { SEARCH_TOKEN, SEARCH_URL } from "utils/const";

const searchClient = instantMeiliSearch(SEARCH_URL, SEARCH_TOKEN, {
  hitsPerPage: 20,
  paginationTotalHits: 20,
  placeholderSearch: false,
});

function HeaderSearch({ refine }) {
  return (
    <InstantSearch indexName="omnisearch" searchClient={searchClient}>
      <HeaderSearchAutocomplete />
    </InstantSearch>
  );
}

export default HeaderSearch;
