import Cookie from "js-cookie";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { API_URL } from "utils/const";

export const registerUser = (username, email, password) => {
  if (typeof window === "undefined") {
    return;
  }

  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/auth/local/register`, { username, email, password })
      .then((res) => {
        const token = jwtDecode(res.data.jwt);
        const expires = Math.min(
          Math.floor((token.exp - token.iat) / 1000 / 60),
          30
        );
        Cookie.set("token", res.data.jwt, { expires });
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const login = (identifier, password) => {
  if (typeof window === "undefined") {
    return;
  }

  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/auth/local/`, { identifier, password })
      .then((res) => {
        const token = jwtDecode(res.data.jwt);
        const expires = Math.min(
          Math.floor((token.exp - token.iat) / 1000 / 60),
          30
        );
        Cookie.set("token", res.data.jwt, { expires });
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const forgotPassword = (email) => {
  if (typeof window === "undefined") {
    return;
  }

  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/auth/forgot-password`, { email })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const resetPassword = (password, passwordConfirmation, code) => {
  if (typeof window === "undefined") {
    return;
  }

  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/auth/reset-password`, {
        password,
        passwordConfirmation,
        code,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const logout = () => {
  Cookie.remove("token");
  window.localStorage.setItem("logout", Date.now());

  // just reload the app
  window.location = "/";
};
