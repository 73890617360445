import { useEffect, useMemo, useState } from "react";
import Head from "next/head";
import Cookie from "js-cookie";
import localforage from "localforage";
import AppContext from "context/app";
import withAuthSync from "components/auth/with-auth-sync";
import Header from "components/header/header";
import Footer from "components/footer/footer";

// styles
import "assets/styles.css";

function OpenCoaster({ Component, pageProps }) {
  const [useLiteMode, setUseLiteMode] = useState(true);
  const [user, setUser] = useState(null);
  const [flashMessage, setFlashMessage] = useState(null);
  const [transparentHeader, setTransparentHeader] = useState(false);
  const [token, setToken] = useState(Cookie.get("token"));
  const [recommendedRecentPark, setRecommendedRecentPark] = useState();
  const [recommendedRecentAttraction, setRecommendedRecentAttraction] =
    useState();
  const [recommendedRecentResort, setRecommendedRecentResort] = useState();
  const [recommendedCacheLoaded, setRecommendedCacheLoaded] = useState(false);

  const contextValue = useMemo(
    () => ({
      user,
      isAuthenticated: !!user,
      setUser,
      flashMessage,
      setFlashMessage,
      transparentHeader,
      setTransparentHeader,
      useLiteMode,
      setUseLiteMode,
      recommendedRecentPark,
      setRecommendedRecentPark,
      recommendedRecentAttraction,
      setRecommendedRecentAttraction,
      recommendedRecentResort,
      setRecommendedRecentResort,
    }),
    [
      user,
      flashMessage,
      transparentHeader,
      useLiteMode,
      recommendedRecentPark,
      recommendedRecentAttraction,
      recommendedRecentResort,
    ]
  );

  useEffect(() => {
    if (token) {
      fetch(`${process.env.apiUrl}/users/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then(async (res) => {
        if (!res.ok) {
          Cookie.remove("token");
          setUser(null);
          setToken(null);
          return null;
        }

        const userData = await res.json();
        setUser(userData);
      });
    }
  }, [token]);

  // update recent cache
  useEffect(() => {
    if (!recommendedCacheLoaded) {
      return;
    }

    localforage.setItem("recommendedRecentPark", recommendedRecentPark);
    localforage.setItem("recommendedRecentResort", recommendedRecentResort);
    localforage.setItem(
      "recommendedRecentAttraction",
      recommendedRecentAttraction
    );
  }, [
    recommendedCacheLoaded,
    recommendedRecentAttraction,
    recommendedRecentPark,
    recommendedRecentResort,
  ]);

  // bring in recent cache
  useEffect(() => {
    async function updateRecentCache() {
      if (
        recommendedRecentPark ||
        recommendedRecentResort ||
        recommendedRecentAttraction
      ) {
        setRecommendedCacheLoaded(true);
        return;
      }

      const cachedRecommendedRecentPark = await localforage.getItem(
        "recommendedRecentPark"
      );

      const cacheRecommendedRecentResort = await localforage.getItem(
        "recommendedRecentResort"
      );

      const cacheRecommendedRecentAttraction = await localforage.getItem(
        "recommendedRecentAttraction"
      );

      setRecommendedRecentPark(cachedRecommendedRecentPark);
      setRecommendedRecentResort(cacheRecommendedRecentResort);
      setRecommendedRecentAttraction(cacheRecommendedRecentAttraction);
      setRecommendedCacheLoaded(true);
    }

    updateRecentCache();
  });

  useEffect(() => {
    document.body.className = "bg-slate-50";
  });

  return (
    <AppContext.Provider value={contextValue}>
      <Head>
        <title>Open Coaster</title>
        <meta
          name="description"
          content="An open free-to-use attractions database"
        />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />

        <meta name="application-name" content="Open Coaster" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="apple-mobile-web-app-title" content="Open Coaster" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="msapplication-config" content="/icons/browserconfig.xml" />
        <meta name="msapplication-TileColor" content="#1f2937" />
        <meta name="msapplication-tap-highlight" content="no" />
        <meta name="theme-color" content="#1f2937" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Open Coaster" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@opencoaster" />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/icons/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/icons/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/icons/favicon-16x16.png"
        />
        <link
          rel="mask-icon"
          href="/icons/safari-pinned-tab.svg"
          color="#1f2937"
        />
        <link rel="icon" href="/favicon.ico" />
        <link rel="manifest" href="/manifest.json" />

        <link rel="preconnect" href="//api.opencoaster.com" />
        <link rel="preconnect" href="//images.opencoaster.com" />
        <link rel="dns-prefetch" href="//search.opencoaster.com" />
      </Head>

      <Header />
      <Component {...pageProps} />
      <Footer />
      <script
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `
                  {
                  "@context": "https://schema.org",
                  "@type": "WebSite",
                  "@id": "https://opencoaster.com/#/schema/website/1",
                  "url": "https://opencoaster.com/",
                  "name": "Open Coaster",
                  "inLanguage": "en-US"
                }
                `,
        }}
      />
    </AppContext.Provider>
  );
}

export default withAuthSync(OpenCoaster);
