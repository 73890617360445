/* eslint-disable import/prefer-default-export */

export const API_URL = "https://api.opencoaster.com";
export const GRAPH_URL = "https://api.opencoaster.com/graphql";
export const IMAGE_URL = "https://images.opencoaster.com";
export const SEARCH_URL = "https://search.opencoaster.com";
export const GEO_URL = "https://geo.opencoaster.com/json";
export const DEBOUNCE_TIME = 300;
export const DEFAULT_FILTER = "NOT contentType=null";
export const SEARCH_TOKEN = "af1149a0bef988303996688de434df822366d8b0d6f1262b95fffba9c84bc2fa";
export const PLUSCODE_KEY =
  "ulGEjbotGwpLe4TuiN0dI4IKEnRNQSrg9M0voZtgelUPw8hupGqkhSRmMryewCgpOSWGt0pIJ%2FuuwVo6qoOUJD8IMPU%3D";
export const RIDE_TIMES = {
  UNKNOWN: "",
  MORNING: "Morning",
  AFTERNOON: "Afternoon",
  NIGHT: "Night",
  EXCLUSIVE: "Exclusive Ride",
};
export const RIDE_POSITION = {
  UNKNOWN: "",
  FRONT: "Front",
  MIDDLE: "Middle",
  BACK: "Back",
};
export const RIDE_POSITION_RESTROOM = {
  UNKNOWN: "",
  FRONT: "Number 1",
  MIDDLE: "Both",
  BACK: "Number 2",
};
export const RIDE_SIDE = {
  UNKNOWN: "",
  LEFT: "Left",
  MIDDLE: "Middle",
  RIGHT: "Right",
};