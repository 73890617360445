import Link from "next/link";

export default function Footer() {
  return (
    <>
      <div className="container m-6 mx-auto bg-amber-300 p-6 text-gray-800">
        <p>
          <span role="img" aria-label="construction sign">
            🚧
          </span>{" "}
          Site Under construction. Feedback:{" "}
          <a
            href="https://twitter.com/opencoaster"
            className="no-underline hover:underline"
            rel="noopener"
          >
            @opencoaster
          </a>{" "}
          <span role="img" aria-label="construction sign">
            🚧
          </span>
        </p>
      </div>
      <nav className="p-6 text-gray-900">
        <div className="container mx-auto">
          <div className="flex flex-row flex-wrap content-center items-center justify-between">
            <div className="flex-1">
              <p>
                Copyright {new Date().getFullYear()}{" "}
                <a
                  href="https://somanyscientists.com"
                  className="no-underline hover:underline"
                >
                  SoManyScientists, LLC.
                </a>{" "}
                <em>It&apos;s Alive!</em>
              </p>
            </div>
            <div className="flex-1 text-right">
              <ul className="flex flex-row-reverse">
                <li key="footer-nav-about" className="mx-2">
                  <Link href="/about">
                    <a className="inline-block hover:underline">About</a>
                  </Link>
                </li>
                <li key="footer-nav-dmca" className="mx-2">
                  <Link href="/dmca">
                    <a className="inline-block hover:underline">DMCA</a>
                  </Link>
                </li>
                <li key="footer-nav-privacy" className="mx-2">
                  <Link href="/privacy">
                    <a className="inline-block hover:underline">Privacy</a>
                  </Link>
                </li>
                <li key="footer-nav-status" className="mx-2">
                  <a
                    className="inline-block hover:underline"
                    href="https://status.opencoaster.com"
                  >
                    Status
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}