import { useContext } from "react";
import Avatar from "react-avatar";

import AppContext from "context/app";

export default function HeaderUserMobile() {
  const { user } = useContext(AppContext);

  if (!user) {
    return null;
  }

  return (
    <div className="flex items-center px-5">
      <div className="flex-shrink-0">
        <Avatar
          email={user.email}
          name={user.username}
          unstyled
          className="h-10 w-10 rounded-full"
        />
      </div>
      <div className="ml-3">
        <div className="text-base font-medium text-white">{user.username}</div>
        <div className="text-sm font-medium text-gray-400">{user.email}</div>
      </div>
    </div>
  );
}
