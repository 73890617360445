import { useContext, Fragment } from "react";
import { Disclosure } from "@headlessui/react";
import { LogoutIcon } from "@heroicons/react/outline";

import { logout } from "utils/auth";
import AppContext from "context/app";
import OCLink from "components/global/link";

export default function HeaderProfileLinksMobile() {
  const { user } = useContext(AppContext);

  return (
    <div className="mt-3 px-2 space-y-1">
      {user ? (
        <>
          <Disclosure.Button
            as={OCLink}
            href={`/profile/${user.id}`}
            className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
          >
            Your Profile
          </Disclosure.Button>
          <Disclosure.Button as={Fragment}>
            <button
              onClick={logout}
              type="button"
              className="flex items-center px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
            >
              <span>Sign out</span>
              <LogoutIcon className="h-4 ml-2" />
            </button>
          </Disclosure.Button>
        </>
      ) : (
        <>
          <Disclosure.Button
            as={OCLink}
            href="/login"
            className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
          >
            Sign in
          </Disclosure.Button>
          <Disclosure.Button
            as={OCLink}
            href="/register"
            className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
          >
            Sign up
          </Disclosure.Button>
        </>
      )}
    </div>
  );
}
