/* eslint-disable @next/next/no-img-element */
import { useState, Fragment } from "react";
import { useRouter } from "next/router";
import { connectAutoComplete } from "react-instantsearch-dom";
import classNames from "classnames";
import { Combobox } from "@headlessui/react";
import { SearchIcon } from "@heroicons/react/solid";
import BuildLink from "utils/build-link";

function Autocomplete({ hits, currentRefinement, refine }) {
  const [query, setQuery] = useState("");
  const router = useRouter();

  return (
    <>
      <Combobox
        as="div"
        className="absolute inset-x-3 top-2 z-10 transform divide-y divide-gray-500 divide-opacity-10 overflow-hidden rounded-xl bg-white bg-opacity-80 shadow-2xl ring-1 ring-black ring-opacity-5 backdrop-blur backdrop-filter transition-all"
        onChange={(hit) => {
          refine("");
          setQuery("");
          const link = BuildLink(hit);
          router.push(link);
        }}
        value={currentRefinement}
      >
        {({ open }) => (
          <>
            <div className="relative">
              <SearchIcon
                className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-900 text-opacity-40"
                aria-hidden="true"
              />
              <Combobox.Button as="div">
                <Combobox.Input
                  className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                  placeholder="Search..."
                  onChange={(event) => {
                    refine(event.currentTarget.value);
                    setQuery(event.currentTarget.value);
                  }}
                  type="search"
                />
              </Combobox.Button>
            </div>

            {hits.length > 0 && query !== "" && (
              <Combobox.Options className="max-h-80 scroll-py-2 divide-y divide-gray-500 divide-opacity-10 overflow-y-auto">
                <li className="p-2">
                  <ul className="text-sm text-gray-700">
                    {hits.map((hit) => {
                      return (
                        <Combobox.Option
                          key={hit.id}
                          value={hit}
                          className={({ active }) =>
                            classNames(
                              "flex cursor-default select-none items-center rounded-md px-3 py-2",
                              active && "bg-gray-900 bg-opacity-5 text-gray-900"
                            )
                          }
                          onMouseEnter={() => {
                            router.prefetch(BuildLink(hit));
                          }}
                          onFocus={() => {
                            router.prefetch(BuildLink(hit));
                          }}
                        >
                          {({ active }) => (
                            <>
                              {hit.photo ? (
                                <img
                                  src={`https://images.opencoaster.com/80x80/${hit.photo.hash}${hit.photo.ext}`}
                                  alt={
                                    hit.photo.alternativeText
                                      ? hit.photo.alternativeText
                                      : hit.title
                                  }
                                  className="h-10 w-10 rounded-lg object-cover object-center"
                                />
                              ) : (
                                <div className="h-10 w-10 flex-shrink-0 rounded-lg object-cover object-center">
                                  <div className={`flex justify-center`}>
                                    <img
                                      className="flex"
                                      src={`/placeholder-${hit.contentType}.svg`}
                                      alt={hit.title}
                                    />
                                  </div>
                                </div>
                              )}
                              <div className="ml-4 flex-auto">
                                <p
                                  className={classNames(
                                    "text-sm font-medium",
                                    active ? "text-gray-900" : "text-gray-700"
                                  )}
                                >
                                  {hit.title}
                                </p>
                                {hit?.parentName && (
                                  <p
                                    className={classNames(
                                      "text-sm",
                                      active ? "text-gray-700" : "text-gray-500"
                                    )}
                                  >
                                    {hit.parentName}
                                  </p>
                                )}
                              </div>
                            </>
                          )}
                        </Combobox.Option>
                      );
                    })}
                  </ul>
                </li>
              </Combobox.Options>
            )}

            {query !== "" && hits.length === 0 && (
              <div className="py-14 px-6 text-center sm:px-14">
                <p className="mt-4 text-sm text-gray-900">
                  We couldn&apos;t find anything with that term. Please try
                  again.
                </p>
              </div>
            )}
          </>
        )}
      </Combobox>
    </>
  );
}

const HeaderSearchAutocomplete = connectAutoComplete(Autocomplete);
export default HeaderSearchAutocomplete;
