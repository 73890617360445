import { useEffect } from "react";

export default function withAuthSync(Component) {
  function Wrapper(props) {
    const syncLogout = (event) => {
      if (event.key === "logout") {
        window.location = "/";
      }
    };

    useEffect(() => {
      window.addEventListener("storage", syncLogout);

      return () => {
        window.removeEventListener("storage", syncLogout);
        window.localStorage.removeItem("logout");
      };
    }, []);

    return <Component {...props} />;
  }

  if (Component.getInitialProps) {
    Wrapper.getInitialProps = Component.getInitialProps;
  }

  return Wrapper;
}
