import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import classnames from "classnames";
import { ChevronDownIcon } from "@heroicons/react/solid";
import OCLink from "components/global/link";

export default function HeaderNav() {
  return (
    <div className="hidden lg:ml-6 lg:block">
      <div className="flex space-x-4">
        {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
        <OCLink
          href="/map"
          className="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
        >
          Map
        </OCLink>
        <OCLink
          href="/resorts"
          className="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
        >
          Resorts
        </OCLink>
        <OCLink
          href="/parks"
          className="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
        >
          Parks
        </OCLink>

        <Menu as="div" className="relative ml-4 flex-shrink-0">
          <div>
            <Menu.Button className="flex items-center rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white">
              <span>More</span>
              <ChevronDownIcon className="ml-1 inline-block h-4" />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-50 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <Menu.Item>
                {({ active }) => (
                  <OCLink
                    href="/companies"
                    className={classnames(
                      active ? "bg-gray-100" : "",
                      "block px-4 py-2 text-sm text-gray-700"
                    )}
                  >
                    Companies
                  </OCLink>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <OCLink
                    href="/manufacturers"
                    className={classnames(
                      active ? "bg-gray-100" : "",
                      "block px-4 py-2 text-sm text-gray-700"
                    )}
                  >
                    Manufacturers
                  </OCLink>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <OCLink
                    href="/attractions"
                    className={classnames(
                      active ? "bg-gray-100" : "",
                      "block px-4 py-2 text-sm text-gray-700"
                    )}
                  >
                    Attractions
                  </OCLink>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
}
