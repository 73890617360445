import { Disclosure } from "@headlessui/react";

import OCLink from "components/global/link";

export default function HeaderNavMobile() {
  return (
    <div className="px-2 pt-2 pb-3 space-y-1">
      {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
      <Disclosure.Button
        as={OCLink}
        href="/resorts"
        className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
      >
        Resorts
      </Disclosure.Button>
      <Disclosure.Button
        as={OCLink}
        href="/parks"
        className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
      >
        Parks
      </Disclosure.Button>
      <Disclosure.Button
        as={OCLink}
        href="/attractions"
        className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
      >
        Attractions
      </Disclosure.Button>
      <Disclosure.Button
        as={OCLink}
        href="/companies"
        className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
      >
        Companies
      </Disclosure.Button>
      <Disclosure.Button
        as={OCLink}
        href="/manufacturers"
        className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
      >
        Manufacturers
      </Disclosure.Button>
    </div>
  );
}
