import { forwardRef } from "react";
import Link from "next/link";

function OCLink(props, ref) {
  const { href, children, ...rest } = props;
  return (
    <Link href={href} forwardedRef={ref}>
      <a {...rest}>{children}</a>
    </Link>
  );
}

export default forwardRef(OCLink);
