/* eslint-disable @next/next/no-img-element */
import Link from "next/link";
import { Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import classNames from "classnames";

import HeaderNav from "./nav";
import HeaderSearch from "./search";
import HeaderNavMobile from "./nav-mobile";
import HeaderProfileLinksMobile from "./profile-links-mobile";
import HeaderProfileLinks from "./profile-links";
import HeaderUserMobile from "./user-mobile";

export default function Header() {
  return (
    <Disclosure as="nav" className="bg-gray-800">
      {({ open }) => (
        <>
          <div className="container mx-auto">
            <div className="relative flex h-16 items-center justify-between">
              <div className="flex items-center px-2 lg:px-0">
                <div className="flex-shrink-0">
                  <Link href="/">
                    <a className="flex items-center text-white">
                      <img
                        className={classNames("mr-3 block h-8 w-auto sm:h-10")}
                        src="/logo.svg"
                        alt=""
                      />
                      <span className="hidden w-auto text-xl font-semibold tracking-tight lg:block">
                        Open Coaster
                      </span>
                    </a>
                  </Link>
                </div>
                <HeaderNav />
              </div>
              <div className="relative flex h-full flex-1">
                <HeaderSearch />
              </div>
              <div className="flex lg:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="hidden lg:ml-4 lg:block">
                <HeaderProfileLinks />
              </div>
            </div>
          </div>

          <Disclosure.Panel className="lg:hidden">
            <HeaderNavMobile />
            <div className="border-t border-gray-700 pt-4 pb-3">
              <HeaderUserMobile />
              <HeaderProfileLinksMobile />
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
