import { Fragment, useContext } from "react";
import { Menu, Transition } from "@headlessui/react";
import classnames from "classnames";
import Avatar from "react-avatar";
import { LogoutIcon, LoginIcon } from "@heroicons/react/outline";

import { logout } from "utils/auth";
import AppContext from "context/app";
import OCLink from "components/global/link";

export default function HeaderProfileLinks() {
  const { user } = useContext(AppContext);

  return (
    <div className="flex items-center">
      {/* Profile dropdown */}
      <Menu as="div" className="ml-4 relative flex-shrink-0">
        <div>
          <Menu.Button className="bg-gray-800 rounded-full flex text-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
            <span className="sr-only">
              Open {user ? "user" : "sign in"} menu
            </span>
            {user ? (
              <Avatar
                email={user.email}
                name={user.username}
                unstyled
                className="h-8 w-8 rounded-full"
              />
            ) : (
              <span className="inline-flex items-center text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                <span>Account</span>
                <LoginIcon className="h-4 ml-2" />
              </span>
            )}
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute z-50 right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            {user ? (
              <>
                <Menu.Item>
                  {({ active }) => (
                    <OCLink
                      href={`/profile/${user.id}`}
                      className={classnames(
                        active ? "bg-gray-100" : "",
                        "block px-4 py-2 text-sm text-gray-700"
                      )}
                    >
                      Your Profile
                    </OCLink>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={logout}
                      type="button"
                      className={classnames(
                        active ? "bg-gray-100" : "",
                        "flex items-center px-4 py-2 text-sm text-gray-700 w-full text-left"
                      )}
                    >
                      <span>Sign out</span>
                      <LogoutIcon className="h-4 ml-2" />
                    </button>
                  )}
                </Menu.Item>
              </>
            ) : (
              <>
                <Menu.Item>
                  {({ active }) => (
                    <OCLink
                      href="/login"
                      className={classnames(
                        active ? "bg-gray-100" : "",
                        "block px-4 py-2 text-sm text-gray-700"
                      )}
                    >
                      Sign in
                    </OCLink>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <OCLink
                      href="/register"
                      className={classnames(
                        active ? "bg-gray-100" : "",
                        "block px-4 py-2 text-sm text-gray-700"
                      )}
                    >
                      Sign up
                    </OCLink>
                  )}
                </Menu.Item>
              </>
            )}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
