export default function BuildLink(item) {
  if (!item.contentType || (item.contentType !== "attraction" && !item.slug)) {
    return null;
  }

  let link = `/${item.contentType}/${item.slug}`;

  // Make attraction urls work
  if (item.contentType === "attraction") {
    if (!item.parentType || !item.parentSlug) {
      return null;
    }

    link = `/${item.parentType}/${item.parentSlug}/${item.slug}`;
  }

  return link;
}
